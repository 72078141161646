import React from 'react';
import './ProfileListItem.css';

interface ProfileListItemProps {
    icon: React.ReactNode;
    value: string;
}

const ProfileListItem: React.FC<ProfileListItemProps> = ({ icon, value }) => {
    return (
        <li className="profile-list-item-li">
            {icon}
            <p className="profile-list-item-value">{value}</p>
        </li>
    );
};

export default ProfileListItem;
import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './MatchesView.css';
import LikesView from './InnerViews/LikesView/LikesView';
import SendedLikesView from './InnerViews/SendedLikesView/SendedLikesView';
import GoldMatchesView from './InnerViews/GoldMatchesView/GoldMatchesView';

const MatchesView: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Likes');

    return (
        <Layout disableNav={true}>
            <div className="main-matches-view">
                <div className="main-matches-tabs-bar">
                    <button
                        className={`main-matches-tab-btn ${activeTab === 'Likes' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Likes')}
                    >
                        Likes
                    </button>
                    <button
                        className={`main-matches-tab-btn ${activeTab === 'Verstuurde likes' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Verstuurde likes')}
                    >
                        Verstuurde likes
                    </button>
                    <button
                        className={`main-matches-tab-btn ${activeTab === 'Gold matches' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Gold matches')}
                    >
                        Gold matches
                    </button>
                </div>
                <div className="main-matches-content">
                    {activeTab === 'Likes' && <LikesView />}
                    {activeTab === 'Verstuurde likes' && <SendedLikesView />}
                    {activeTab === 'Gold matches' && <GoldMatchesView />}
                </div>
            </div>
        </Layout>
    );
};

export default MatchesView;
import React from 'react';
import './TopNavBar.css';
import logo from '../../../assets/img/test-img.jpg';

interface TopNavBarProps {
    showMatchingPercentage?: boolean;
    percentage?: number;
    children?: React.ReactNode;
}

const TopNavBar: React.FC<TopNavBarProps> = ({ showMatchingPercentage, percentage, children }) => {
    return (
        <nav className="main-top-nav">
            <ul className="main-top-nav-listing-ul">
                <li className="main-top-nav-logo-li">
                    
                </li>

                <li className="main-top-nav-quick-btns-li">
                    <ul className="main-top-nav-quick-btns-listing-ul">
                        {children}
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default TopNavBar;
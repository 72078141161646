import React, { useRef, useState } from 'react';
import './ProfileCard.css';
import testimg from '../../../../assets/img/test-img.jpg';
import testimg2 from '../../../../assets/img/test-img-2.jpg';
import testimg3 from '../../../../assets/img/test-img-3.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

interface ProfileCardProps {
    name: string;
    age: number;
}

const ProfileCard: React.FC<ProfileCardProps> = (props) => {
    const images = [testimg, testimg2, testimg3];
    const [currentIndex, setCurrentIndex] = useState(0);
    const profileCardRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={profileCardRef} className="profile-card">
            <Swiper
                className='swipe-card-swiper'
                slidesPerView={1}
                pagination={{ clickable: true }}
                modules={[Pagination]}
            // onSlideChange={(swiper) => console.log('slide change', swiper.activeIndex)}
            // onSwiper={(swiper) => console.log(swiper)}
            >
                {images.map((img, index) => (
                    <SwiperSlide key={index}>
                        <img src={img} alt="" className="swipe-card-img" />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="profile-card-overlay">
                <div className="profile-card-bottom-area">
                   
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
import React from 'react';
import './SendedLikesView.css';
import SendedLikeItem from '../../../../components/MatchesView/SendedLikeItem/SendedLikeItem';

const SendedLikesView: React.FC = () => {
    return (
        <div className='sended-likes-view-content'>
            <ul className="sended-likes-view-listing-ul">
                <SendedLikeItem />
                <SendedLikeItem />
                <SendedLikeItem />
                <SendedLikeItem />
            </ul>
        </div>
    );
};

export default SendedLikesView;
import React from 'react';
import './LikesView.css';
import MatchesFilterControls from '../../../../components/MatchesView/MatchesFilterControls/MatchesFilterControls';
import LikeViewItem from '../../../../components/MatchesView/InnerViews/LikesView/LikeViewItem/LikeViewItem';

interface LikesViewProps {
    // Add any props you need here
}

const LikesView: React.FC<LikesViewProps> = () => {
    // Add your component logic here

    return (
        <div className='likes-view-content'>
            <MatchesFilterControls />
            <ul className="likes-view-listing-ul">
                <LikeViewItem />
                <LikeViewItem />
                <LikeViewItem />
                <LikeViewItem />
            </ul>
        </div>
    );
};

export default LikesView;
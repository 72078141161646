import React from 'react';
import './LikesViewItem.css';
import src from '../../../assets/img/test-img.jpg';
import { Link } from 'react-router-dom';


interface LikesViewItemProps {
}

const LikesViewItem: React.FC<LikesViewItemProps> = (props) => {
    return (
        <li className='likes-view-item'>
            <div className="likes-inner-view-div">
                <img src={src} alt="Constant" className="likes-view-img" />
            </div>
            <p className="likes-view-txt-p">10 likes</p>
        </li>
    );
};

export default LikesViewItem;
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { motion, useMotionValue, useTransform, useAnimation } from 'framer-motion';
import './SwipeCard.css';
import testimg from '../../../assets/img/test-nino.jpg';
import testimg2 from '../../../assets/img/test-ninon.jpg';
import testimg3 from '../../../assets/img/test-img.jpg';
import { MaterialSymbolsVerifiedRounded } from '../../../assets/icons/VerifiedIcon';
import { MaterialSymbolsLocationOnOutline } from '../../../assets/icons/LocationIcon';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { ArrowUpIcon } from '../../../assets/icons/ArrowUpIcon';

interface SwipeCardProps {
    onSwipe: (direction: string, name: string) => void;
    onCardLeftScreen: (name: string) => void;
    isActive: boolean;
    card: { name: string; age: number, percentage: number };
    zIndex: number;
    handleProfileDetailNavigation: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SwipeCard = forwardRef(({
    onSwipe,
    onCardLeftScreen,
    isActive,
    card,
    zIndex,
    handleProfileDetailNavigation
}: SwipeCardProps, ref) => {
    const images = [testimg, testimg2, testimg3];
    const [isLiked, setIsLiked] = useState(false);
    const [showHeart, setShowHeart] = useState(false);
    const [heartPosition, setHeartPosition] = useState({ x: 0, y: 0 });
    const swipeCardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const rect = swipeCardRef.current?.getBoundingClientRect();
        if (rect) {
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setHeartPosition({ x, y });
        }
        setIsLiked(true);
        setShowHeart(true);
        setTimeout(() => {
            setShowHeart(false);
        }, 1000); // Adjust the timeout to match the duration of the animation
    };

    return (
        <motion.div
            ref={swipeCardRef}
            className="swipe-card"
            onDoubleClick={handleDoubleClick}
        >
            <div className="swipe-card-content">
                <Swiper
                    className='swipe-card-swiper'
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                // onSlideChange={(swiper) => console.log('slide change', swiper.activeIndex)}
                // onSwiper={(swiper) => console.log(swiper)}
                >
                    {images.map((img, index) => (
                        <SwiperSlide key={index}>
                            <img src={img} alt="" className="swipe-card-img" />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="swipe-card-overlay-div">
                    <div className="swipe-card-wrapper">
                        <div className="swipe-card-top-area">
                        </div>
                        <div className="swipe-card-bottom-area">
                            <div className="swipe-card-bottom-wrapper">
                                <div className="swipe-card-bottom-labels-area">
                                    <div className="swipe-card-bottom-label">
                                        <p className="swipe-card-bottom-label-txt-p">{card.percentage}%</p>
                                    </div>
                                </div>
                                <div className="swipe-card-bottom-info">
                                    <div className="swipe-card-bottom-bar">
                                        <div className="swipe-card-bottom-left-area">
                                            <div className="swipe-card-bottom-name-age">
                                                <h2 className="swipe-card-name">{card.name}</h2>
                                                <p className="swipe-card-age">{card.age}</p>
                                                {MaterialSymbolsVerifiedRounded({ className: 'swipe-card-verified-icon' })}
                                            </div>
                                            <div className="swipe-card-bottom-additional-info">
                                                {MaterialSymbolsLocationOnOutline({ className: 'swipe-card-location-icon' })}
                                                <p className="swipe-card-bottom-distance-txt-p">10 km uit de buurt</p>
                                            </div>
                                        </div>
                                        <div className="swipe-card-bottom-right-area">
                                            <button
                                                className="swipe-card-bottom-btn"
                                                onClick={handleProfileDetailNavigation}
                                            >
                                                {ArrowUpIcon({ className: 'swipe-card-bottom-btn-icon' })}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="swipe-card-bottom-controls-area">
                                        {/* Optional control buttons or actions */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showHeart && (
                    <div className="hearts-container" style={{ top: heartPosition.y, left: heartPosition.x }}>
                        <div className="big-heart">❤️</div>
                        <div className="small-heart small-heart-1">❤️</div>
                        <div className="small-heart small-heart-2">❤️</div>
                        <div className="small-heart small-heart-3">❤️</div>
                    </div>
                )}
            </div>
        </motion.div>
    );
});

export default SwipeCard;
import React from 'react';
import './MatchItem.css';
import testimg from '../../../assets/img/test-img.jpg';

interface MatchItemProps {
    // Define the props for the MatchItem component here
}

const MatchItem: React.FC<MatchItemProps> = (props) => {
    // Implement the MatchItem component logic here

    return (
        <li className='chat-match-item'>
            <div className="chat-match-inner-view-div">
                <img src={testimg} alt="Constant" className="chat-match-img" />
            </div>
            <p className="chat-match-txt-p">Constant</p>
        </li>
    );
};

export default MatchItem;
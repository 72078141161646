import React from 'react';
import Layout from '../../components/Layout/Layout';
import { StreamlineShield2Solid } from '../../assets/icons/ShieldIcon';
import { OouiSettings } from '../../assets/icons/SettingsIcon';
import './UserProfileView.css';
import testimg from '../../assets/img/test-img.jpg';

interface UserProfileViewProps {
    // Add any props you need for the UserProfileView component
}

const UserProfileView: React.FC<UserProfileViewProps> = () => {
    // Add your component logic here

    return (
        <Layout childrenoptions={
            <><li className="main-top-nav-quick-btn-li">
                <button className="main-top-nav-quick-btn" type='button'>
                    {StreamlineShield2Solid({ className: 'main-top-nav-quick-btn-icon' })}
                </button>
            </li><li className="main-top-nav-quick-btn-li">
                    <button className="main-top-nav-quick-btn" type='button'>
                        {OouiSettings({ className: 'main-top-nav-quick-btn-icon' })}
                    </button>
                </li></>

        }>
            <div className="user-profile-view">
                <div className="user-profile-top-area">
                    <div className="user-profile-img-div">
                        <img src={testimg} alt="Constant" className="user-profile-img" />
                        <div className="user-info-area">
                            <p className="user-name">Constant</p>
                            <p className="user-age">22</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserProfileView;
import React from 'react';
import './LikeViewItem.css';
import testimg from '../../../../../assets/img/test-img.jpg';

interface LikeViewItemProps {
}

const LikeViewItem: React.FC<LikeViewItemProps> = ( props ) => {
    return (
        <li className="like-view-item">
            <img src={testimg} alt="Constant" className="like-view-img" />
            <div className="like-view-overlay">
                <p className="like-view-user-info-txt-p">Constant, 24</p>
            </div>
        </li>
    );
};

export default LikeViewItem;
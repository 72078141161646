import React, { ReactNode } from 'react';
import BottomNavBar from './BottomNavBar/BottomNavBar';
import './Layout.css';
import TopNavBar from './TopNavBar/TopNavBar';

type LayoutProps = {
    children: ReactNode;
    showMatchingPercentage?: boolean;
    percentage?: number;
    disableQuickButts?: boolean;
    disableNav?: boolean;
    childrenoptions?: ReactNode;
};

const Layout = ({ children, showMatchingPercentage, percentage, disableQuickButts, disableNav, childrenoptions }: LayoutProps) => {
    return (
        <div id='main-layout-div'>
            {disableNav ?? <TopNavBar showMatchingPercentage={showMatchingPercentage} percentage={percentage} children={childrenoptions} />}
            <div className="main-content-div">
                {children}
            </div>
            <BottomNavBar />
        </div>
    );
};

export default Layout;
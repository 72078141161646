import React from 'react';
import './ProfileList.css';

interface ProfileListProps {
    icon: React.ReactNode;
    title: string;
    children: React.ReactNode;
}

const ProfileList: React.FC<ProfileListProps> = ({ icon, title, children }) => {
    return (
        <li className="profile-list">
            <li className="profile-list-top-li">
                {icon}
                <p className="profile-list-title">{title}</p>
            </li>
            {children}
        </li>
    );
};

export default ProfileList;
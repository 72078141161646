import React from 'react';
import './BottomNavBar.css';
import { HeroiconsHeartSolid } from '../../../assets/icons/HeartIcon';
import { HeroiconsChatBubbleOvalLeftEllipsis } from '../../../assets/icons/ChatIcon';
import { HeroiconsUser16Solid } from '../../../assets/icons/UserIcon';
import { HeroiconsMagnifyingGlass16Solid } from '../../../assets/icons/SearchIcon';
import { Link, useMatch } from 'react-router-dom';
import { MingcuteLoveFill } from '../../../assets/icons/MatchesIcon';

interface BottomNavBarProps {
    // Add any props you need here
}

const BottomNavBar: React.FC<BottomNavBarProps> = () => {
    // Add your component logic here\

    const swipeViewActive = useMatch('/');
    const searchViewActive = useMatch('/search');
    const matchViewActive = useMatch('/matches');
    const chatViewActive = useMatch('/chats');
    const profileViewActive = useMatch('/user_profile');


    return (
        <nav className="main-bottom-nav">
            <ul className='main-bottom-nav-listing-ul'>
                <Link to="/" className='main-bottom-nav-li'>
                    <li className={`main-bottom-nav-li ${swipeViewActive && 'active'}`}>
                        {HeroiconsHeartSolid({ className: 'main-bottom-nav-icon' })}
                    </li>
                </Link>
                {/* <Link to="/search" className='main-bottom-nav-li'>
                    <li className={`main-bottom-nav-li ${searchViewActive && 'active'}`}>
                        {HeroiconsMagnifyingGlass16Solid({ className: 'main-bottom-nav-icon' })}
                    </li>
                </Link> */}
                <Link to="/matches" className='main-bottom-nav-li'>
                    <li className={`main-bottom-nav-li ${matchViewActive && 'active'}`}>
                        {MingcuteLoveFill({ className: 'main-bottom-nav-icon' })}
                    </li>
                </Link>
                <Link to="/chats" className='main-bottom-nav-li'>
                    <li className={`main-bottom-nav-li ${chatViewActive && 'active'}`}>
                        {HeroiconsChatBubbleOvalLeftEllipsis({ className: 'main-bottom-nav-icon' })}
                    </li>
                </Link>
                <Link to="/user_profile" className='main-bottom-nav-li'>
                    <li className={`main-bottom-nav-li ${profileViewActive && 'active'}`}>
                        {HeroiconsUser16Solid({ className: 'main-bottom-nav-icon' })}
                    </li>
                </Link>
            </ul>
        </nav>
    );
};

export default BottomNavBar;
import React from 'react';
import Layout from '../../components/Layout/Layout';
import MatchItem from '../../components/ChatsView/MatchItem/MatchItem';
import './ChatsView.css';
import LikesViewItem from '../../components/ChatsView/LikesViewItem/LikesViewItem';
import ChatItem from '../../components/ChatsView/ChatItem/ChatItem';

const ChatsView: React.FC = () => {
    return (
        <Layout disableNav={true}>
            <div className="main-chats-view">
                <div className="main-chats-matches-wrapper">
                    <h3 className="main-chats-matches-txt-h3">
                        Nieuwe matches
                    </h3>
                    <ul className="main-chats-matches-listing-ul">
                        <LikesViewItem />
                        <MatchItem />
                        <MatchItem />
                        <MatchItem />
                        <MatchItem />
                        <MatchItem />
                        <MatchItem />
                        <MatchItem />
                    </ul>
                </div>
                <div className="main-chats-wrapper-div">
                    <h3 className="main-chats-txt-h3">
                        Chats
                    </h3>
                    <ul className="main-chats-listing-ul">
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                        <ChatItem message='Hoe gaat het met je?' sender='Constant' />
                    </ul>
                </div>
            </div>
        </Layout>
    );
};

export default ChatsView;
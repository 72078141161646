import logo from './logo.svg';
import './App.css';
import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SwipeView from './views/SwipeView/SwipeView';
import SearchingView from './views/SearchingView/SearchingView';
import ChatsView from './views/ChatsView/ChatsView';
import MatchesView from './views/MatchesView/MatchesView';
import UserProfileView from './views/UserProfileView/UserProfileView';

function App() {
  return (
    <NextUIProvider>
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<DashboardView/>} /> */}
          <Route path="/" element={<SwipeView />} />
          <Route path="/search" element={<SearchingView />} />
          <Route path="/chats" element={<ChatsView />} />
          <Route path="/matches" element={<MatchesView />} />
          <Route path="/user_profile" element={<UserProfileView />} />
        </Routes>
      </Router>
    </NextUIProvider>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';
import './SwipeView.css';
import Layout from '../../components/Layout/Layout';
import SwipeCard from '../../components/SwipeView/SwipeCard/SwipeCard';
import SwipeControls from '../../components/SwipeView/SwipeControls/SwipeControls';
import TopNavBar from '../../components/Layout/TopNavBar/TopNavBar';
import { HeroiconsSolidBell } from '../../assets/icons/BellIcon';
import { HeroiconsAdjustmentsHorizontalSolid } from '../../assets/icons/FilterIcon';
import { motion, useAnimation } from 'framer-motion';
import ProfileView from './InnerViews/ProfileView/ProfileView';

const SwipeView: React.FC = () => {
    const swipeCardRef = useRef<{ swipeRight: () => void; swipeLeft: () => void }>(null);
    const [cards, setCards] = useState([
        { name: 'Constant', age: 24, percentage: 90 },
        { name: 'Test', age: 24, percentage: 80 },
        { name: 'Test2', age: 24, percentage: 70 },
        { name: 'Test3', age: 24, percentage: 60 },
        { name: 'Test4', age: 24, percentage: 50 },
        { name: 'Test5', age: 24, percentage: 40 },
        { name: 'Test6', age: 24, percentage: 30 },
    ]);

    const [activeCardIndex, setActiveCardIndex] = useState(0);
    const [showProfilePage, setShowProfilePage] = useState(false);
    const controls = useAnimation();

    const handleSwipe = (direction: string, name: string): void => {
        console.log(`${name} swiped ${direction}`);
        setActiveCardIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex === 3) {
                setCards((prevCards) => prevCards.slice(1));
                return newIndex - 1; // Adjust index after removing the first card
            }
            return newIndex;
        });
    };

    const handleCardLeftScreen = (name: string): void => {
        console.log(`${name} left the screen`);
    };


    const handleProfileDetailNavigation = (e: React.MouseEvent<HTMLButtonElement>) => {
        // e.stopPropagation();
        setShowProfilePage(true);
        controls.start({ y: 0 });
        console.log('Profile detail navigation triggered');
    };

    let percentage = cards[activeCardIndex]?.percentage || 0;

    const triggerSwipeRight = () => {
        if (swipeCardRef.current) {
            swipeCardRef.current.swipeRight();
        }
    };

    const triggerSwipeLeft = () => {
        if (swipeCardRef.current) {
            swipeCardRef.current.swipeLeft();
        }
    };

    const swipeListAreaRef = useRef<HTMLDivElement>(null);
    const sensitivityFactor = 0.25; // Fine-tuned sensitivity

    // Debounce function to limit the rate at which the handleWheel function is executed
    const debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const handleWheel = debounce((event: WheelEvent) => {
        if (!swipeListAreaRef.current) return;

        // Adjust scroll position based on the sensitivity factor
        swipeListAreaRef.current.scrollTop += event.deltaY * sensitivityFactor;
    }, 20); // A short debounce period to avoid jitter

    useEffect(() => {
        const swipeListArea = swipeListAreaRef.current;
        if (swipeListArea) {
            swipeListArea.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (swipeListArea) {
                swipeListArea.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    const handleDragEnd = (event: any, info: any) => {
        if (info.offset.x < -100) { // Adjust the threshold as needed
            setShowProfilePage(true);
            controls.start({ x: 0 });
        }
    };

    const handleCloseProfilePage = () => {
        controls.start({ y: '100%' }).then(() => {
            setShowProfilePage(false);
        });
    };

    return (
        <Layout showMatchingPercentage={true} percentage={percentage} childrenoptions={
            <><li className="main-top-nav-quick-btn-li">
                <button className="main-top-nav-quick-btn" type='button'>
                    {HeroiconsSolidBell({ className: 'main-top-nav-quick-btn-icon' })}
                </button>
            </li><li className="main-top-nav-quick-btn-li">
                    <button className="main-top-nav-quick-btn" type='button'>
                        {HeroiconsAdjustmentsHorizontalSolid({ className: 'main-top-nav-quick-btn-icon' })}
                    </button>
                </li></>

        }>
            <div className="swipe-list-area" ref={swipeListAreaRef}>
                {cards.map((card, index) => (
                    <SwipeCard
                        ref={swipeCardRef}
                        key={card.name}
                        card={card}
                        onSwipe={handleSwipe}
                        onCardLeftScreen={handleCardLeftScreen}
                        isActive={index === activeCardIndex} // Only the top card is active
                        zIndex={cards.length - index} // Ensure the top card has the highest z-index
                        handleProfileDetailNavigation={handleProfileDetailNavigation}
                    />
                ))}
                {/* <SwipeControls
                    onSwipeLeft={() => console.log('Manual Swipe Left')}
                    onSwipeRight={() => console.log('Manual Swipe Right')}
                    onSuperLike={() => console.log('Super Like')}
                    onProfileDetails={() => console.log('Profile Details')}
                    onUndo={() => console.log('Undo')}
                /> */}
            </div>
            {showProfilePage && (
                <motion.div
                    className="profile-page"
                    initial={{ y: '100%' }}
                    animate={{ y: 0 }} // Directly animate to the final state
                    exit={{ y: '100%' }}
                    transition={{ type: 'smooth', stiffness: 300 }}
                >
                    {/* <button onClick={handleCloseProfilePage}>Close</button>
                    Profile page content here */}
                    <ProfileView card={cards[activeCardIndex]} handleCloseProfilePage={handleCloseProfilePage} />
                </motion.div>
            )}
        </Layout>
    );
};

export default SwipeView;

import React, { useState } from 'react';
import Layout from '../../../../components/Layout/Layout';
import ProfileCard from '../../../../components/Layout/ProfileView/ProfileCard/ProfileCard';
import { useNavigate, useParams } from 'react-router-dom';
import './ProfileView.css';
import { FaArrowDown } from '../../../../assets/icons/ArrowDownIcon';
import ProfileList from '../../../../components/Layout/ProfileView/ProfileList/ProfileList';
import { PersonInfoIcon } from '../../../../assets/icons/PersonInfoIcon';
import { RulerIcon } from '../../../../assets/icons/RulerIcon';
import ProfileListItem from '../../../../components/Layout/ProfileView/ProfileList/ProfileListItem/ProfileListItem';
import { MaterialSymbolsLocationOnOutline } from '../../../../assets/icons/LocationIcon';
import { HomeIcon } from '../../../../assets/icons/HomeIcon';
import { QuoteIcon } from '../../../../assets/icons/QuoteIcon';
import { HopistelIcon } from '../../../../assets/icons/HospitalIcon';

interface ProfileViewProps {
    // Add any props you need for the ProfileView component
    card: { name: string; age: number, percentage: number };
    handleCloseProfilePage: () => void;
}

const ProfileView: React.FC<ProfileViewProps> = (props) => {
    // Add your component logic here

    const { id } = useParams<{ id: string }>();

    const navigate = useNavigate();

    const handleBackNavigation = () => {
        navigate(-1); // Navigate back one step in the history
    };

    return (
        <Layout disableNav={true}>
            <nav className="main-profile-top-nav">
                <ul className="main-profile-top-nav-listing-ul">
                    <li className="main-profile-nav-name-li">
                        <p className="main-profile-nav-name"><b>Constant</b>, <span className="profile-age">24</span></p>
                    </li>
                    <li className="main-profile-nav-quick-btn-li">
                        <button className="main-profile-nav-quick-btn" type='button' onClick={props.handleCloseProfilePage}>
                            {FaArrowDown({ className: 'main-profile-nav-quick-btn-icon' })}
                        </button>
                    </li>
                </ul>
            </nav>
            <div className="main-profile-view">
                <ProfileCard name='Constant' age={24} />
                <ul className="main-profile-info-listing-ul">
                    <ProfileList icon={<>{QuoteIcon({ className: 'main-profile-list-icon' })}</>} title='Over mij' >
                        <p className="profile-list-bio-txt-p">Whiskey Cola</p>
                    </ProfileList>
                    <ProfileList icon={<>{PersonInfoIcon({ className: 'main-profile-list-icon' })}</>} title='Korte samenvatting' >
                        <ProfileListItem icon={<>{MaterialSymbolsLocationOnOutline({ className: 'profile-list-item-icon' })}</>} value='1 km van je vandaan' />
                        <ProfileListItem icon={<>{HomeIcon({ className: 'profile-list-item-icon' })}</>} value='Amsterdam' />
                        <ProfileListItem icon={<>{HopistelIcon({ className: 'profile-list-item-icon' })}</>} value='Blarcium' />
                        <ProfileListItem icon={<>{RulerIcon({ className: 'profile-list-item-icon' })}</>} value='1.90 meter' />
                    </ProfileList>
                </ul>
            </div>
        </Layout>
    );
};

export default ProfileView;
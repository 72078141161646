import React from 'react';
import './SendedLikeItem.css';
import testimg from '../../../assets/img/test-img.jpg';
import { Link } from 'react-router-dom';

interface SendedLikeItemProps {
}

const SendedLikeItem: React.FC<SendedLikeItemProps> = (props) => {
    return (
        <Link to="/profile/:id" className="sended-like-view-link">
            <li className="sended-like-view-item">
                <img src={testimg} alt="Constant" className="sended-like-view-img" />
                <div className="sended-like-view-overlay">
                    <p className="sended-like-view-user-info-txt-p">Constant, 24</p>
                </div>
            </li>
        </Link>
    );
};

export default SendedLikeItem;
import React from 'react';
import Layout from '../../components/Layout/Layout';
import './SearchingView.css';

interface SearchingViewProps {
    // Add any props you need here
}

const SearchingView: React.FC<SearchingViewProps> = () => {
    // Add your component logic here

    return (
        <Layout>
            <div className="main-searching-view">
            </div>
        </Layout>
    );
};

export default SearchingView;
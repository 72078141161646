import React from 'react';
import './ChatItem.css';
import testimg from '../../../assets/img/test-img.jpg';

interface ChatItemProps {
    message: string;
    sender: string;
}

const ChatItem: React.FC<ChatItemProps> = ({ message, sender }) => {
    return (
        <li className="chat-item">
            <img src={testimg} alt="Constant" className="chat-item-img" />
            <div className="chat-message-area">
                <div className="chat-message-sender-area">
                    <p className="chat-message-sender-txt-p">{sender}</p>
                </div>
                <div className="message">{message}</div>
            </div>
        </li>
    );
};

export default ChatItem;